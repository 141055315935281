<template>
  <div>
    <div class="row justify-between items-center q-pb-md heading">
      <div class="title">
        <slot name="title">
          <h2 class="text-h5 text-weight-medium no-margin">
            {{ title ?? name }}
          </h2>
        </slot>
      </div>
      <div
        v-if="slideCount > 1"
        class="row q-gutter-x-sm navigation gt-sm"
      >
        <q-btn
          :icon="mdiChevronLeft"
          text-color="primary"
          padding="3px 2px"
          style="font-size: 20px"
          flat
          dense
          @click="carousel?.slidePrev()"
        />
        <q-btn
          :icon="mdiChevronRight"
          text-color="primary"
          padding="3px 2px"
          style="font-size: 20px"
          flat
          dense
          @click="carousel?.slideNext()"
        />
      </div>
    </div>

    <div
      v-if="pending"
      class="row no-wrap overflow-hidden q-col-gutter-md"
    >
      <div
        v-for="item in 5"
        :key="item"
        class="item col-12 col-sm-6 col-md-4 col-lg-3"
      >
        <ProductCardSkeleton
          :lines="2"
          style="min-height: 350px"
        />
      </div>
    </div>

    <slot
      v-else-if="!items.length"
      name="empty"
    >
      <lazy-q-banner
        class="text-white bg-red full-width q-my-md"
        inline-actions
      >
        No products found that match your request.
        <template #action>
          <q-btn
            flat
            square
            color="white"
            label="Retry"
            @click="refresh()"
          />
        </template>
      </lazy-q-banner>
    </slot>

    <swiper
      v-else
      :modules="[Autoplay]"
      :slides-per-view="visibleItems"
      :space-between="16"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }"
      :loop="items.length > visibleItems"
      auto-height
      @swiper="(swiper) => carousel = swiper"
    >
      <swiper-slide
        v-for="item in items"
        :key="item.id"
      >
        <ProductCard :product="item" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts" setup>
import type SwiperClass from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import { mdiChevronLeft, mdiChevronRight } from '@quasar/extras/mdi-v7';
import { whenever } from '@vueuse/core';
import type { Product } from '~/types/search';
import type { FilterByQuery } from '~/types/typesense';

import 'swiper/scss';

const props = defineProps({
  search: {
    type: String,
    default: '*',
  },
  query: {
    type: Object as PropType<FilterByQuery<Product>>,
    default: () => ({}),
  },
  sortBy: {
    type: Array as PropType<string[]>,
  },
  size: {
    type: Number,
    default: 12,
  },
  name: {
    type: String,
  },
  title: {
    type: String,
  },
  dataKey: {
    type: String,
    required: true,
  },
});

useProvideCollection(props.name);

const { $typesense, $bus } = useNuxtApp();
const { buildFieldsList, buildFilterBy } = useTypesenseUtils();
const $q = useQuasar();
const collection = useCollection();
const carousel = ref<SwiperClass | null>(null);

const filterBy = computed(() => buildFilterBy(props.query));

const { data, status, refresh } = await useAsyncData(props.dataKey,
  () => $typesense.search<Product>('kygunco_products', {
    q: props.search,
    highlight_fields: 'none',
    include_fields: buildFieldsList<Product>(PRODUCT_INCLUDE_FIELDS),
    query_by: buildFieldsList<Product>(PRODUCT_QUERY_BY),
    filter_by: filterBy.value,
    sort_by: props.sortBy,
    per_page: props.size,
    enable_analytics: props.search != '*',
  }),
  {
    lazy: true,
    server: false,
    watch: [() => props],
    deep: true,
    getCachedData: getCachedPayload,
  },
);

const items = computed(() => data.value?.hits?.map(h => h.document) ?? []);

const pending = computed(() => import.meta.server || status.value == 'pending');

const visibleItems = computed(() => {
  if (import.meta.server) {
    return 1;
  }

  switch ($q.screen.name) {
    case 'sm':
      return 2;
    case 'md':
      return 3;
    case 'lg':
      return 4;
    case 'xl':
      return 5;
    default:
      return 1;
  }
});

const slideCount = computed(() => {
  return Math.ceil(items.value.length / visibleItems.value);
});

whenever(
  () => !!items.value.length,
  () => $bus.emit('catalog:view', items.value, collection),
  {
    immediate: true,
  },
);

onDeactivated(() => carousel.value?.autoplay.pause());

onActivated(() => carousel.value?.autoplay.start());
</script>

<style lang="scss" scoped>
:deep(.swiper) {
  z-index: initial;
}

:deep(.swiper-wrapper) {
  align-items: stretch;
  z-index: initial;
}

@media (min-width: 1920px) {
  .row > .item {
    height: auto;
    width: 20%;
  }
}
</style>
